type FocusableElement = HTMLButtonElement | HTMLAnchorElement;

export default class FocusLock {
  private _htmlElement: HTMLElement;
  private _firstFocusable: FocusableElement | undefined;
  private _lastFocusable: FocusableElement | undefined;
  private _phantomElement: HTMLButtonElement | undefined;

  constructor(htmlElement: HTMLElement) {
    this._htmlElement = htmlElement;
  }

  lock() {
    const focusable = this._htmlElement.querySelectorAll(
      'a[href], button:not([disabled]):not([tabindex="-1"]',
    ) as NodeListOf<FocusableElement>;

    this._firstFocusable = focusable[0];
    this._lastFocusable = focusable[focusable.length - 1];

    this._phantomElement = this._createPhantomElement();
    this._htmlElement.appendChild(this._phantomElement);

    // Each Web Component has a shadow root that can only pick up events triggered within
    // the shadow root itself. This is fine when we want to detect whether there is a focus
    // on an element within the modal. When our focus leave the modal our shadow root has
    // no way of picking up the event, so we need to rely on the document itself. So:
    //
    // 1. Shadow Root listen for changes in focus for elements within itself
    // 2. Document listen for changes in focus for elements outside of the shadow root
    this._htmlElement.addEventListener(
      'focus',
      this._shadowRootFocusHandler,
      true,
    );
    document.addEventListener('focus', this._documentFocusHandler, true);
  }

  release(nodeToFocus = {}) {
    this._htmlElement.removeEventListener(
      'focus',
      this._shadowRootFocusHandler,
      true,
    );
    document.removeEventListener('focus', this._documentFocusHandler, true);

    this._removePhantomElement();

    if (nodeToFocus instanceof HTMLElement) {
      nodeToFocus.focus();
    }
  }

  private _createPhantomElement() {
    const phantomElement = document.createElement('button');
    phantomElement.setAttribute('aria-hidden', 'true');
    phantomElement.className = 'visually-hidden';

    return phantomElement;
  }

  private _removePhantomElement() {
    if (this._phantomElement) {
      this._htmlElement.removeChild(this._phantomElement);
    }
  }

  /**
   * Returns `true` if the event came from an element with aria-modal="true". Prevents this focus lock function from
   * fighting with other potential focus lock functions on 3P modals (i.e. the Privy newsletter popup).
   *
   * @param {FocusEvent} evt received
   * @returns {boolean} whether the focus event came from a modal type.
   * @private
   */
  private _isEventFromModal(evt: FocusEvent) {
    const modalTarget = evt.composedPath().find((element) => {
      return element instanceof Element && element.ariaModal;
    });
    return Boolean(modalTarget);
  }

  private _getShadowRootHost() {
    const rootNode = this._htmlElement.getRootNode();

    if (rootNode instanceof ShadowRoot) {
      return rootNode.host;
    } else {
      return null;
    }
  }

  private _documentFocusHandler = (evt: FocusEvent) => {
    if (
      this._lastFocusable &&
      evt.target !== this._getShadowRootHost() &&
      !this._isEventFromModal(evt)
    ) {
      evt.preventDefault();
      evt.stopPropagation();
      this._lastFocusable.focus();
    }
  };

  private _shadowRootFocusHandler = (evt: Event) => {
    if (this._firstFocusable && evt.target === this._phantomElement) {
      this._firstFocusable.focus();
    }
  };
}
